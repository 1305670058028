import React, { useState } from 'react';
import BlogHeader from './BlogHeader';
import { useGetBlogsQuery } from 'src/store/blog';
import Loader from 'src/components/Loader';
import { Box, Card, CardContent, CardMedia, Container, Grid, Stack, Typography } from '@mui/material';
import ProjectsSearch from '../Gallry/ProjectsSearch';
import Label from 'src/components/Label';
import Iconify from 'src/components/Iconify';
import DeleteBlog from './DeleteBlog';
import { useNavigate } from 'react-router-dom';
import { WEBSITE_URL } from 'src/utils/constant';

const BlogHome = () => {
  const { data, isLoading } = useGetBlogsQuery();
  const [searchTerm, setSearchTerm] = useState('');
  const filteredBlog = data?.filter((blogItem) => blogItem.title.toLowerCase().includes(searchTerm.toLowerCase()));
  const [open, setOpen] = useState(false);
  const [deletedItem, setDeletedItem] = useState(null);
  const navigate = useNavigate();

  const handleSearch = (event) => {
    setSearchTerm(event);
  };

  return (
    <Container maxWidth="xl">
      <BlogHeader title={'Blogs'} link={'/blog/add'} buttonText={'New Blog'} iconClass={'eva:plus-fill'} />
      <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
        <ProjectsSearch projects={data} value={searchTerm} onChange={handleSearch} />
      </Stack>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container spacing={3}>
          {filteredBlog && filteredBlog.length > 0 ? (
            filteredBlog.map((blog) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={blog.id}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <Card>
                  <Box sx={{ pt: blog.image ? 0 : '50px', position: 'relative' }}>
                    {blog.image && (
                      <CardMedia
                        component="img"
                        height="200"
                        image={WEBSITE_URL + blog.image}
                        alt={blog.title}
                        sx={{ 
                          objectFit: 'cover',
                          mb: 2
                        }}
                      />
                    )}
                    <Label
                      variant="filled"
                      color={'info'}
                      sx={{
                        zIndex: 9,
                        top: 10,
                        right: 10,
                        position: 'absolute',
                        textTransform: 'uppercase',
                        py: 3,
                        px: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => navigate(`/blog/${blog?.id}`)}
                    >
                      <Iconify icon={'eva:edit-outline'} sx={{ width: 24, height: 24 }} />
                    </Label>
                    <Label
                      variant="filled"
                      color={'error'}
                      sx={{
                        zIndex: 9,
                        top: 10,
                        right: 60,
                        position: 'absolute',
                        textTransform: 'uppercase',
                        py: 3,
                        px: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setDeletedItem(blog);
                        setOpen(true);
                      }}
                    >
                      <Iconify icon={'eva:trash-2-fill'} sx={{ width: 24, height: 24 }} />
                    </Label>
                  </Box>
                  <CardContent>
                    <Typography variant="h5" component="h3" noWrap>
                      {blog?.title}
                    </Typography>
                    <Typography color="textSecondary">{new Date(blog?.date).toLocaleDateString()}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="h5" component="h3" sx={{ px: 3 }}>
              No Blogs Found
            </Typography>
          )}
        </Grid>
      )}
      {open && <DeleteBlog open={open} setOpen={setOpen} item={deletedItem} setDeletedItem={setDeletedItem} />}
    </Container>
  );
};

export default BlogHome;
