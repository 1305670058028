import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import Loader from 'src/components/Loader';
import { useGetBlogQuery, useUpdateBlogMutation } from 'src/store/blog';
import ProjectHeader from '../projects/ProjectHeader';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQuill } from 'react-quilljs';

import 'quill/dist/quill.snow.css';
import { useGetTagsQuery } from 'src/store/tags';
import { WEBSITE_URL } from 'src/utils/constant';

const EditBlog = () => {
  const { quill, quillRef } = useQuill();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useGetBlogQuery(id);

  const { data: availableTags } = useGetTagsQuery();
  const [selectedTags, setSelectedTags] = useState([]);
  const [edit, { isLoading: isUpdating }] = useUpdateBlogMutation();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleEdit = async () => {
    if (content && title) {
      try {
        const formData = new FormData();
        formData.append('title', title);
        formData.append('body', content);
        formData.append('date', new Date().toISOString().split('T')[0]);
        formData.append('tags', selectedTags.toString());

        if (image) {
          formData.append('image', image);
        }

        const response = await edit({
          form: formData,
          id: id,
        }).unwrap();
        toast.success('Blog Edited successfully');

        navigate(-1);
      } catch (error) {
        console.log(error);
        if (error.data) {
          Object.keys(error.data).forEach((key) => {
            toast.error(`${key}: ${error.data[key].join(', ')}`);
          });
        } else {
          toast.error("This didn't work!");
        }
        console.error('Error submitting form:', error);
      }
    } else {
      if (!content) toast.error('Please add content');
      if (!title) toast.error('Please add title');
    }
  };

  useEffect(() => {
    if (data?.tags) {
      setSelectedTags(data.tags.map((tag) => tag.id.toString()));
    }
    if (data?.image) {
      setImagePreview(data.image);
    }
  }, [data]);

  React.useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        const content = quill.root.innerHTML;
        setContent(content);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (data && quill) {
      setTitle(data?.title);
      setContent(data.body);
      quill.clipboard.dangerouslyPasteHTML(data.body);
    }
  }, [quill]);

  return (
    <>
      <ProjectHeader title={'Edit Blog'} link={'/blog'} buttonText={'Back'} iconClass={'eva:arrow-ios-back-fill'} />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <TextField
            fullWidth
            label="Title"
            sx={{
              '.css-6c8syq-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(145, 158, 171, 0.32)',
              },
            }}
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />

          <Box sx={{ mt: 2 }}>
            <input accept="image/*" type="file" onChange={handleImageChange} style={{ marginBottom: '1rem' }} />
            {imagePreview && (
              <Box sx={{ mt: 1, mb: 2 }}>
                <img
                  src={image ? imagePreview : WEBSITE_URL + imagePreview}
                  alt="Blog preview"
                  style={{
                    maxWidth: '200px',
                    maxHeight: '200px',
                    objectFit: 'cover',
                    borderRadius: '4px',
                  }}
                />
              </Box>
            )}
          </Box>

          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="tags-label">Tags</InputLabel>
            <Select
              labelId="tags-label"
              id="tags-select"
              multiple
              value={selectedTags}
              onChange={(event) => {
                const {
                  target: { value },
                } = event;
                setSelectedTags(typeof value === 'string' ? value.split(',') : value);
              }}
              input={<OutlinedInput label="Tags" />}
              renderValue={(selected) =>
                selected
                  .map((id) => availableTags?.find((tag) => tag.id.toString() === id)?.name)
                  .filter(Boolean)
                  .join(', ')
              }
            >
              {availableTags?.map((tag) => (
                <MenuItem key={tag?.id.toString()} value={tag?.id.toString()}>
                  <Checkbox checked={selectedTags.indexOf(tag?.id.toString()) > -1} />
                  <ListItemText primary={tag?.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ width: '100%', height: '100%', mt: 3 }}>
            <div ref={quillRef} />
          </Box>
          {content && (
            <Box sx={{ mt: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
                className="ql-editor"
              ></div>
            </Box>
          )}
          <Box
            sx={{
              mt: 3,
              textAlign: 'right',
            }}
          >
            <LoadingButton
              loading={isUpdating}
              onClick={handleEdit}
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              Edit Blog
            </LoadingButton>
          </Box>
        </>
      )}
    </>
  );
};
export default EditBlog;
